// @ts-nocheck
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  CircularProgress,
  Typography,
  Button,
  TextField,
  Box,
} from "@mui/material";
import {
  getAuth,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
} from "firebase/auth";
import {
  query,
  onSnapshot,
  doc,
  getDoc,
  collection,
  addDoc,
  orderBy,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { Trans, useTranslation } from 'react-i18next';

import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Send from "@mui/icons-material/Send";

import useAuth from "../hooks/useAuth";
import { ReactComponent as GooglePlay } from "../assets/googlePlay.svg";
import { ReactComponent as AppStore } from "../assets/appStore.svg";
import { ReactComponent as Person } from "../assets/Person.svg";
import { db, auth } from "../config/firebase";
import Center from "../components/utils/Center";
import { useAppToast } from "../hooks/useAppToast";
import LanguageSelector from "../components/utils/LanguageSelector";

const FormSignInWithlink = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useAppToast();

  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const matches = useMediaQuery("(max-width:480px)");
  useEffect(() => {
    // console.log("useEffect");
    if (isSignInWithEmailLink(auth, window.location.href)) {
      setLoading(true);
      signInWithEmailLink(
        auth,
        window.localStorage.getItem("email"),
        window.location.href
      ).catch(async (error) => {
        // setLoading(false);
        console.log("error while calling signInWithEmailLink", error);
        if (
          error?.code === "auth/invalid-action-code" ||
          error?.code === "auth/expired-action-code"
        ) {
          toast.error(t`Ce lien est expiré ou a déjà été utilisé`);
          await auth.signOut();
          navigate("/");
        }
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      });
    }
  }, []);

  const validationSchema = yup.object({
    email: yup.string().email().required(t`Champ obligtoire`),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await sendSignInLinkToEmail(auth, values.email, {
          url: "https://qrcode.lnfound.com/conversation/t0pYsKyZ9wiEVWDqIkT7",
          handleCodeInApp: true,
        });
        // Save the email locally so you don't need to ask the user for it again
        window.localStorage.setItem("email", values.email);
        setShowMessage(true);
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        // console.log("errorCode", errorCode);
        // console.log("errorMessage", errorMessage);
      }
    },
  });
  if (loading) {
    return (
      <Center>
        <CircularProgress
          sx={{
            color: "white",
          }}
        />
      </Center>
    );
  }
  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          maxWidth: "40rem",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          justifyContent: "center",
          height: "100vh",
          alignItems: "center",
        }}
      >
        {showMessage ? (
          <>
            <Typography variant="h4" textAlign={"center"} gutterBottom>
              <Trans>
                Veuillez vérifier votre adresse e-mail
              </Trans>
            </Typography>
            <Typography
              lineHeight={2}
              variant="p"
              textAlign={"center"}
              gutterBottom
            >
              <Trans>
                Un e-mail a été envoyé à l'adresse
              </Trans>
              <b> {window.localStorage.getItem("email")}</b> <br />
              <Trans>
                pour permettre d'accéder à vos conversations.
              </Trans>
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h4" textAlign={"center"} gutterBottom>
              <Trans>
                Votre session a expiré
              </Trans>
            </Typography>
            <Typography lineHeight={2} variant="p" textAlign={"center"}>
              <Trans>
                Veuillez saisir votre adresse e-mail pour recevoir un lien
                d'authentification vous permettant d'accéder à vos conversations.
              </Trans>
            </Typography>
            <TextField
              id="email"
              placeholder="Mon email"
              name="email"
              style={{
                width: matches ? "80vw" : "50%",
                marginInline: matches ? "2rem" : 0,
              }}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <Button
              style={{
                fontWeight: "600",
                color: "#2575C4",
                height: 46,
                fontSize: "17",
                width: matches ? "50vw" : "20vw",
                background: "#00FFE1",
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
                borderRadius: "35px",
                marginInline: matches ? "2rem" : 0,
              }}
              type="submit"
              variant="contained"
            >
              <Trans>
                Envoyer
              </Trans>
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};
const SendInput = ({ text }) => {
  let { id } = useParams();
  const { t } = useTranslation();

  const validationSchema = yup.object({
    message: yup.string().required(t`Champ obligatoire`),
  });
  const formik = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await addDoc(collection(db, "conversations", `${id}`, "messages"), {
        _id: uuidv4(),
        createdAt: new Date().toISOString(),
        text: values.message,
        user: {
          _id: auth?.currentUser?.uid,
          avatar: null,
          name: null,
          isAnonymous: true,
        },
      });
      formik.resetForm();
    },
  });
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",

        alignItems: "center",
        width: "100%",
        p: 1,
      }}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <TextField
        fullWidth
        margin="normal"
        id="message"
        name="message"
        placeholder={t`Taper votre message`}
        type="text"
        value={formik.values.message}
        onChange={formik.handleChange}
        error={formik.touched.message && Boolean(formik.errors.message)}
        helperText={formik.touched.message && formik.errors.message}
      />
      <Button
        sx={{
          backgroundColor: "#2575C4",
          fontWeight: "600",
          color: "white",
          height: 50,
          mx: 1,

          fontSize: "17",
          boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
          borderRadius: "35px",
        }}
        type="submit"
        variant="contained"
      >
        <Send />
      </Button>
    </Box>
  );
};
const Messages = ({ data }) => {
  return (
    <Box
      sx={{
        flexDirection: "row",
      }}
    >
      {data.map((item) => (
        <Box
          key={item.id}
          sx={{
            display: "flex",
            flexDirection:
              auth.currentUser?.uid === item.userId ? "row-reverse" : "row",
          }}
        >
          <Box
            sx={{
              borderRadius: 5,
              px: 1,
              py: 1,
              my: 1,
              mx: 2,
              backgroundColor:
                auth.currentUser?.uid === item.userId ? "#2753C1" : "#F2F4F5",
            }}
          >
            <Typography
              variant="subtitle1"
              color={auth.currentUser?.uid === item.userId ? "white" : "black"}
            >
              {item.text}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const Conversation = () => {
  let navigate = useNavigate();
  const auth = getAuth();

  let { id } = useParams();
  // const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [message, setMessage] = useState<boolean>(false);
  const { isLoggedIn, loading, user } = useAuth();
  const [pending, setPending] = useState<boolean>(false);

  const theme = createTheme({
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            "@media (max-width:600px)": {
              fontSize: "22px",
            },
            "@media (min-width:600px)": {
              fontSize: "2rem",
            },
            fontWeight: 500,
          },
          h5: {
            "@media (max-width:600px)": {
              fontSize: "16px",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: "#fff",
            "&.Mui-disabled": {
              backgroundColor: "#e4e4e4",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginTop: 5,
            marginLeft: 0,
            fontWeight: 600,
          },
        },
      },
    },
  });
  const updateConversation = async () => {
    let q = query(doc(db, "conversations", `${id}`));
    const q2 = query(collection(db, "conversations", `${id}`, "messages"));
    try {
      const conversations = await getDoc(q);
      await setDoc(
        q,
        {
          membersIds: [user.uid, conversations.data().membersIds[1]],
        },
        { merge: true }
      );

      let querySnapshot = await getDocs(q2);
      await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          if (doc.data().user.isAnonymous) {
            await setDoc(
              doc.ref,
              {
                user: {
                  _id: user.uid,
                },
              },
              { merge: true }
            );
          }
        })
      );
    } catch (error) {
      console.error("error while calling updateConversation", error);
    }
  };
  useEffect(() => {
    if (user) {
      setPending(true);
      updateConversation();
      const q = query(
        collection(db, "conversations", `${id}`, "messages"),
        orderBy("createdAt", "asc")
      );
      onSnapshot(q, (querySnapshot) => {
        // console.log(querySnapshot.size);
        if (!querySnapshot.empty) {
          let messages = querySnapshot.docs.map((snap) => {
            return {
              id: snap.id,
              text: snap.data().text,
              userId: snap.data().user._id,
            };
          });
          setData(messages);
        }
        setPending(false);
      });
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps
  if (pending) {
    return (
      <Center>
        <CircularProgress
          sx={{
            color: "white",
          }}
        />
      </Center>
    );
  }
  // alert("user", user);

  return (
    <ThemeProvider theme={theme}>
      {isLoggedIn() ? (
        <>
          <Box
            sx={{
              my: 5,
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              component="div"
            >
              <Box marginRight={1}>
                <Person />
              </Box>
              <Typography variant="h1" gutterBottom>
                LnFound - Direct Chat
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100vw",
              height: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Paper
              sx={{
                px: 1,
                width: "80vw",
                height: "80vh",
                maxWidth: "700px",
                maxHeight: "700px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                position: "relative",
              }}
              elevation={3}
            >
              <Paper
                sx={{
                  width: "calc( 100% - 20px )",
                  overflowY: "scroll",
                  height: "calc( 100% - 80px )",
                }}
                elevation={0}
              >
                <Messages data={data} />
              </Paper>

              <SendInput />
            </Paper>
          </Box>
          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
            }}
          >
            <Typography
              marginBottom={3}
              textAlign="center"
              variant="h5"
              component="h4"
              gutterBottom
            >
              <Trans>
                Conversez directement avec le propriétaire et retrouvez
                vous aussi vos objets perdus en {""}
              </Trans>
              <b>
                <a
                  style={{ color: "white" }}
                  href="https://apps.apple.com/dz/app/lnfound/id1642695747"
                >
                  <Trans>
                    téléchargeant gratuitement l'application !
                  </Trans>
                </a>
              </b>
            </Typography>
            <Box
              sx={{
                "@media (max-width:600px)": {
                  flexDirection: "column",
                },
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyItems: "center",
              }}
            >
              <Box
                target="_blank"
                href="https://play.google.com/store/apps"
                sx={{
                  marginX: 5,
                  marginY: 1,
                }}
                component="a"
              >
                <GooglePlay />
              </Box>
              <Box
                target="_blank"
                href="https://apps.apple.com/dz/app/lnfound/id1642695747"
                component="a"
                sx={{
                  marginBottom: 1,
                }}
              >
                <AppStore />
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <FormSignInWithlink />
      )
      }
    </ThemeProvider >
  );
};

export default Conversation;
